import {
	takeEvery,
	put,
	select,
	call,
	fork,
	all,
	delay,
} from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { sportRiskManagementAPI } from '../../../../helpers/api/sportRiskManagement';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import socketActions from '../../../socket/actions';
import tableActions from '../../../tables/actions';
import notifications from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';
import { deriveTablePagination } from '../../../../selectors/tables';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { RootState } from '../../../store';
import { IApiResponse, ISagaActionType } from '../../../types';
import { actions as riskSportBetsModalSlice } from '../modal/slice';
import { actions as riskSportBetsListSlice } from '../list/slice';
import {
	IActionListRefreshSocket,
	IActionMarketIDsRefreshSocket,
	IAdaptedRiskData, IEventSelectionSocketResponse,
	IGetRiskDataParams,
	IRiskBetsItem, IRiskBetsItemResponse, ISportListGetStore,
} from './types';
import { actions } from './slice';
import actionsSportRiskList from './actions';
import {
	adaptBet,
	adaptBetsList,
	adaptSelection,
	getListParams,
	updateRiskDataSelections,
	updateRiskModalDataDetails,
} from './utils';
import uniq from 'lodash/uniq';
import { getCurrencies } from '../../../settings/currency/utils';
import {  ICurrency as ICurrencyWithCode  } from '../../../settings/currency/types';

const tableType = TABLE_TYPES.sportRiskManagement;

const messages = {
	errorListReload: 'riskBets.list.reload.error',
};

function getStoreData(state: RootState) {
	const { SportRiskManagement, Tables, AppTabs, Settings } = state;
	const {
		riskManagementBetsSlice: { riskSportBetsList, riskSportBetsFilter },
	} = SportRiskManagement;
	return {
		betIDs       : riskSportBetsList.betIDs,
		entities     : riskSportBetsList.entities,
		sportRiskData: riskSportBetsList.sportRiskData,
		filter       : riskSportBetsFilter.filterData,
		sorting      : Tables.get(tableType).sorting,
		//eslint-disable-next-line
		// @ts-ignore
		pagination   : deriveTablePagination( tableType, state ),
		currencyList : Settings.CurrencyModule.get('list'),
		activeTabID  : AppTabs.get('activeTabID'),
		modalData  		: SportRiskManagement.riskManagementBetsSlice.riskSportBetsModal.modalData,

	};
}

function* listReload() {
	yield takeEvery(
		actionsSportRiskList.SPORT_RISK_BETS_LIST_RELOAD,
		function* () {
			yield put(actions.uiRefresh({ loading: true }));
			const { filter, sorting, pagination, activeTabID, currencyList } = yield select(
				getStoreData
			);
			const filterCopy = cloneDeep(filter);
			const params: IGetRiskDataParams = getListParams(
				filterCopy,
				sorting,
				pagination
			);
			let betIDs = [];
			let marketIDs = [];
			let selectionIDs = [];
			let entities = {};
			let totalCount = 0;

			try {
				const currencies: ICurrencyWithCode[] = yield getCurrencies(currencyList);
				const response: IApiResponse<IRiskBetsItemResponse[]> = yield call(
					sportRiskManagementAPI.getSportRisk,
					params
				);
				if (response && response.status === 200) {
					const result: IAdaptedRiskData = adaptBetsList(response.data.data, currencies);

					betIDs = result.betIDs;
					entities = result.entities;
					marketIDs = result.marketIDs;
					selectionIDs = result.selectionIDs;
					totalCount = getHeadersTotalCount(response.headers) || betIDs.length;

					yield put(actions.setRiskData(result.riskList));
					yield put(actions.setRiskEntities(entities));
					yield put(actions.setRiskBetIDs(betIDs));
					yield put(actions.setRiskMarketIDs(marketIDs));
					yield put(socketActions.riskManagementBetsSubscribe(activeTabID, betIDs));
					yield put(socketActions.riskManagementMarketIDsSubscribe(`${activeTabID}-marketIDs`, marketIDs));
					yield put(socketActions.riskManagementSubscribeSelectionIDs(`${activeTabID}-selectionIDs`, uniq(selectionIDs)));

				}
				yield put(tableActions.paginationRefresh(tableType, { totalCount }));
			} catch (error) {
				notifications.showError(messages.errorListReload);
				logger.log(error);
			}
			yield put(actions.uiRefresh({ loading: false }));
		}
	);
}

function* riskMarketIDSUpdateSocket() {
	yield takeEvery(
		actionsSportRiskList.SPORT_RISK_BETS_SOCKET_MARKET_IDS_UPDATE_LIST,
		function* ({ data }: IActionMarketIDsRefreshSocket) {
			yield put(actions.uiRefresh({ loading: true }));
			const { sportRiskData } = yield select(getStoreData);
			const clonedRikData = cloneDeep(sportRiskData);

			try {
				if (sportRiskData.length) {
					const listWithUpdatedMarketIDs = clonedRikData.map((item) => {
						if (item.details && item.details.length) {
							return {
								...item,
								details: item.details.map((detail) => {
									if (detail.marketID === data.market_id) {
										return {
											...detail,
											eventMarkets: {
												marketID     : data.market_id,
												reason       : data.reason,
												suspend      : data.suspend,
												suspendID    : data.suspend,
												currentMargin: data.margin,
												id           : data.id,
											},
										};
									}
									return detail;
								}),
							};
						}
						return item;
					});
					yield put(actions.setRiskData(listWithUpdatedMarketIDs));
				}
				yield put(
					riskSportBetsModalSlice.riskModalDataDetailsRefresh({
						popoverVisible: false,
						suspendLoading: false,
						successSaved  : true,
						marketID      : data.market_id,
						suspendID     : data.suspend,
						suspend       : data.suspend,
						reason        : data.reason,
						margin        : data.margin,
						id            : data.id,

						marginPopoverVisible: false,
						marginLoading       : false,
						oddPopoverVisible   : false,
						oddPriceLoading     : false,
						successMarginSaved  : true,
					})
				);
			} catch (error) {
				logger.log(error);
			}
			yield put(actions.uiRefresh({ loading: false }));
		}
	);
}

function* riskBetsUpdateSocket() {
	yield takeEvery(
		actionsSportRiskList.SPORT_RISK_BETS_SOCKET_UPDATE_LIST,
		function* ({ data }: IActionListRefreshSocket) {
			yield put(actions.uiRefresh({ loading: true }));
			const { sportRiskData, entities } = yield select(getStoreData);
			const listDataCloned = cloneDeep(sportRiskData);
			const entitiesCloned = cloneDeep(entities);
			try {
				const result: IRiskBetsItem = adaptBet(data, []);
				if (listDataCloned.length) {
					listDataCloned.forEach((item: IRiskBetsItem) => {
						if (item.betID === result.betID) {
							item.statusID = result.statusID;
						}
					});
					yield put(actions.setRiskData(listDataCloned));
					yield put(
						actions.setRiskEntities({
							...entitiesCloned,
							[result.betID]: result,
						})
					);
					yield put(
						riskSportBetsModalSlice.riskModalDataRefresh({
							statusID       : result.statusID,
							eventSelections: undefined,
						})
					);
					yield put(
						riskSportBetsListSlice.uiRefresh({ updateRowID: result.betID })
					);
				}
			} catch (error) {
				logger.log(error);
			}
			yield put(actions.uiRefresh({ loading: false }));
			yield delay(2000);
			yield put(riskSportBetsListSlice.uiRefresh({ updateRowID: -1 }));
		}
	);
}

function* listRefreshSocket() {
	yield takeEvery(
		actionsSportRiskList.SPORT_RISK_BETS_SOCKET_REFRESH_LIST,
		function* ({ data }: IActionListRefreshSocket) {
			yield put(actions.uiRefresh({ loading: true }));
			const { sportRiskData, entities, betIDs, activeTabID } = yield select(
				getStoreData
			);
			const listDataCloned = cloneDeep(sportRiskData);
			const entitiesCloned = cloneDeep(entities);
			const clonedIDs = cloneDeep(betIDs);

			const currentSelectionIDs = listDataCloned.map((item) => item.details.map((detail) => detail.eventSelections.id)).flat();
			try {
				const result: IRiskBetsItem = adaptBet(data, []);
				if (listDataCloned.length) {
					listDataCloned.unshift(result);
					entitiesCloned[result.betID] = result;
					clonedIDs.push(result.betID);
					const selectionIDs = result.details.map((detail) => detail.eventSelections.id);
					const newSelectionIDs = selectionIDs.filter((id) => !currentSelectionIDs.includes(id));

					yield put(actions.setRiskData(listDataCloned));
					yield put(actions.setRiskEntities(entitiesCloned));
					yield put(riskSportBetsListSlice.setRiskBetIDs(clonedIDs));
					yield put(
						socketActions.riskManagementBetsSubscribe(activeTabID, clonedIDs)
					);
					if (newSelectionIDs.length) {
						yield put(socketActions.riskManagementSubscribeSelectionIDs(`${activeTabID}-selectionIDs`, uniq(newSelectionIDs)));
					}
					yield put(
						riskSportBetsListSlice.uiRefresh({ updateRowID: result.betID })
					);
				}
			} catch (error) {
				logger.log(error);
			}
			yield put(actions.uiRefresh({ loading: false }));
		}
	);
}

function* selectionIDsUpdateList() {
	yield takeEvery(actionsSportRiskList.SPORT_RISK_SOCKET_UPDATE_SELECTION_IDS_LIST, function* (action: ISagaActionType<IEventSelectionSocketResponse>) {
		const { sportRiskData, modalData }: ISportListGetStore = yield select(getStoreData);
		const adapted = adaptSelection(action.data);
		const cloned = cloneDeep(sportRiskData);
		const clonedModalData = cloneDeep(modalData);
		const updated = updateRiskDataSelections(cloned, adapted);
		const updatedModalData = updateRiskModalDataDetails(clonedModalData, adapted);
		yield put(actions.setRiskData(updated));
		yield put(riskSportBetsModalSlice.riskModalDataRefresh(updatedModalData));
	});
}

export default function* sportRiskListSaga() {
	yield all([
		fork(listReload),
		fork(riskBetsUpdateSocket),
		fork(riskMarketIDSUpdateSocket),
		fork(listRefreshSocket),
		fork(selectionIDsUpdateList),
	]);
}
