import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';
import FilterComparator from '../../../helpers/filterComparator';
import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import { createEntities } from '../../utility';
import { isArray, isEmpty } from 'lodash';

const { isValidID, isValidDateRange, isValidArray,isValidString } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	ID                     : isValidID,
	userID                 : isValidID,
	amount                 : isValidID,
	typeID                 : isValidID,
	statusID               : isValidID,
	moneyTypeID            : isValidID,
	currencyID             : isValidID,
	code                   : isValidString,
	externalReferenceNumber: isValidString,
	
	amountRange: isValidArray,
	dateRange  : isValidDateRange,
};

export const defaultValuesTransactionFilter = {
	code                   : null,
	externalReferenceNumber: null,
	transactionID          : null,
	userID                 : null,
	amount                 : null,
	transactionType        : null,
	status                 : null,
	moneyTypeID            : null,
	currencyID             : null,
	amountRange            : null,
	dateRange              : null,
};
export const availableFields = [
	'ID',
	'typeID',
	'statusID',
	'externalReferenceNumber',
	'moneyTypeID',
	'currencyID',
	'productID',
	'balanceBefore',
	'balanceAfter',
	'ip',
	'ipCountry',
	'code',
	'bonusType',
	'bonusEligibility',
	'bonusAmount',
	'totalBonus',
	'amount',
	'amountUSD',
	'user',
	'created',
	'data',
];

export const fields = {
	ID                     : 'id',
	typeID                 : 'type_id',
	statusID               : 'status_id',
	externalReferenceNumber: 'external_reference_number', //string
	userID                 : 'user_id',
	moneyTypeID            : 'money_type_id',
	amount                 : 'amount',
	amountUSD              : 'amount_usd',
	amountEUR              : 'amount_eur',
	currencyID             : 'currency_id',
	productID              : 'product_id',
	data                   : 'data',                      // string
	balanceBefore          : 'balance_before',
	balanceAfter           : 'balance_after',

	channelID: 'channel_id',
	ip       : 'ip',                        // string
	ipCountry: 'ip_country',                // string
	sourceID : 'source_id',
	code     : 'code',                      // string

	sourceTypeID    : 'source_type_id',
	bonusType       : 'bonus_type',
	sourceAccount   : 'source_account',            // string
	sourceAmount    : 'source_amount',
	bonusEligibility: 'bonus_eligibility',         // boolean
	bonusPercentage : 'bonus_percentage',
	bonusAmount     : 'bonus_amount',
	totalBonus      : 'total_bonus',
	actionNumber    : 'action_number',

	depositType: 'deposit_type',

	amountFrom: 'amount_from',
	amountTo  : 'amount_to',
	dateFrom  : 'from',
	dateTo    : 'to',

	userName: 'user_name',
	created : 'created',
};

const listAdapter           = createListDataAdapter();
const transactionComparator = createTransactionComparator();

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
		builder.addValue('include_user', true);
	}

	builder.addField(rules.isID, 'ID', fields.ID);
	builder.addField(rules.isID, 'typeID', fields.typeID);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'moneyTypeID', fields.moneyTypeID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isID, 'sourceID', fields.sourceID);
	builder.addField(rules.isID, 'websiteID', fields.websiteID);

	builder.addField(rules.isString, 'externalReferenceNumber', fields.externalReferenceNumber);
	builder.addField(rules.isString, 'code', fields.code);

	builder.addField(rules.isNullOrNumber, 'amount', fields.amount);
	builder.addField(rules.isNullOrNumber, 'amountUSD', fields.amountUSD);

	builder.addRangeField(rules.isNumberRange, filter.amountRange, [fields.amountFrom, fields.amountTo]);
	builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [fields.dateFrom, fields.dateTo]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptTransactionList(rawData = []) {

	const adaptedData  = rawData ? listAdapter.adaptList(rawData) : [];

	const entities       = createEntities(adaptedData, 'ID');
	const transactionIDs = Object.keys(entities);
	
	const result = {
		transactionIDs,
		entities,
	};

	return result;
}

export function adaptTransaction(rawData = {}) {

	const adaptedData = listAdapter.adapt(rawData);

	adaptedData.username = rawData.user.username || '';
	adaptedData.userID = rawData.user.id || '';

	return adaptedData;
}

// Compare ----------------------------------------------------------------------------------------

export function compareTransactionWithFilter(transaction, filter) {
	if (!transaction) {
		return false;
	}
	const hasMatches = transactionComparator.compare(transaction, filter);

	return hasMatches;
}

// Adapters ---------------------------------------------------------------------------------------

function createListDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'moneyTypeID', fields.moneyTypeID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'productID', fields.productID);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	adapter.addField(rules.string, 'externalReferenceNumber', fields.externalReferenceNumber);
	adapter.addField(rules.string, 'ip', fields.ip);
	adapter.addField(rules.string, 'ipCountry', fields.ipCountry);
	adapter.addField(rules.string, 'code', fields.code);

	adapter.addField(rules.number, 'balanceBefore', fields.balanceBefore);
	adapter.addField(rules.number, 'balanceAfter', fields.balanceAfter);
	adapter.addField(rules.number, 'bonusType', fields.bonusType);
	adapter.addField(rules.number, 'bonusPercentage', fields.bonusPercentage);
	adapter.addField(rules.number, 'bonusAmount', fields.bonusAmount);
	adapter.addField(rules.number, 'totalBonus', fields.totalBonus);
	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'amountUSD', fields.amountUSD);
	adapter.addField(rules.number, 'amountEUR', fields.amountEUR);

	adapter.addField(rules.bool, 'bonusEligibility', fields.bonusEligibility);
	adapter.addField(rules.fullDate, 'created', fields.created);

	adapter.addField(rules.object, 'data', fields.data);
	adapter.addField(rules.string, 'userName', fields.userName);

	return adapter;
}

function createTransactionComparator() {

	const comparator = new FilterComparator();
	const rules = comparator.RULES;

	comparator.addField(rules.isEqual, 'gameID', 'gameID');
	comparator.addField(rules.isEqual, 'userID', 'userID');
	comparator.addField(rules.isEqual, 'gameCurrencyID', 'gameCurrencyID');
	comparator.addField(rules.isEqual, 'gameProviderID', 'gameProviderID');

	comparator.addField(rules.isEqual, 'userName', 'userName');
	comparator.addField(rules.isEqual, 'gameName', 'gameName');
	comparator.addField(rules.isEqual, 'gameIP', 'gameIP');
	comparator.addField(rules.isEqual, 'userRegistrationIP', 'userRegistrationIP');
	comparator.addField(rules.isEqual, 'gameIPCountry', 'gameIPCountry');

	comparator.addField(rules.inArrayID, 'userStatusID', 'userStatusIDs');

	comparator.addField(rules.inNumberRange, 'gameBetNumber', 'gameBetNumber');

	return comparator;
}

export function createReportParams(filter, sorting, reportFields, availableFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields, availableFields);

	return params;
}

export function getReportFields(reportFields, availableFields) {
	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}