import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isArray, isEmpty, isUndefined } from 'lodash';

import HeaderCell from '../HeaderCell';
import ListPagination from '../../../containers/ListPagination';
import { ADMIN_TABLE_TYPES } from '../../../helpers/commonConstants';
import { createColumn } from '../../../helpers/utils';
import TableDragResize from '../../uielements/TableDragResize';
import tablesActions from '../../../redux/tables/actions';

import { Wrapper } from './ListTable.style';
import SettingsControl from './SettingsControl';
import { deriveTableSorting, deriveTableSavedColumns, deriveTablePagination, deriveTableLoading } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';

class ListTable extends Component {

	static propTypes = {
		rowKey          : PropTypes.string,
		customColorsRows: PropTypes.bool,
		rowColors       : PropTypes.string,
		rowColorKey     : PropTypes.string,
		tableType       : PropTypes.string.isRequired,
		savedColumns    : PropTypes.array.isRequired,
		columns         : PropTypes.shape({ // Has 4 props: cols, lang, defaultKeys(optional), readOnly(optional)
			cols: PropTypes.arrayOf(PropTypes.shape({
				id    : PropTypes.string.isRequired,
				render: PropTypes.func,
			})).isRequired,
			lang       : PropTypes.object.isRequired,
			defaultKeys: PropTypes.arrayOf(PropTypes.string), //optional
			readOnly   : PropTypes.bool, //optional
		}).isRequired,
		dataSource          : PropTypes.arrayOf(PropTypes.object).isRequired,
		sortableColumns     : PropTypes.arrayOf(PropTypes.string),
		nonSortableColumns  : PropTypes.arrayOf(PropTypes.string),
		sortedColumn        : PropTypes.string,
		footer              : PropTypes.func,
		saveTables          : PropTypes.func.isRequired,
		getSavedTables      : PropTypes.func.isRequired,
		filterApply         : PropTypes.func.isRequired,
		listReload          : PropTypes.func,
		sortingRefresh      : PropTypes.func.isRequired,
		paginationRefresh   : PropTypes.func.isRequired,
		cutCells            : PropTypes.bool,
		noPagination        : PropTypes.bool,
		expandingData       : PropTypes.object,
		pagination          : PropTypes.object.isRequired,
		loading             : PropTypes.bool.isRequired,
		tableLoading        : PropTypes.bool.isRequired,
		loadingUI           : PropTypes.bool,
		tableRowSocketUpdate: PropTypes.number,
		resizableAction     : PropTypes.bool,
	};

	static defaultProps = {
		rowKey              : 'id',
		customColorsRows    : null,
		footer              : null,
		cutCells            : true,
		sortableColumns     : [],
		nonSortableColumns  : [],
		sortedColumn        : '',
		expandingData       : {},
		listReload          : null,
		noPagination        : false,
		loadingUI           : false,
		tableRowSocketUpdate: -1,
		rowColors           : '',
		rowColorKey         : '',
		resizableAction     : false,
	};

	constructor(props) {
		super(props);
		this.state = {
			realColumns    : {},
			modifiedColumns: [],
		};

		this.getVisibleColumns     = this.getVisibleColumns.bind(this);
		this.getRealColumns        = this.getRealColumns.bind(this);
		this.prepareVisibleColumns = this.prepareVisibleColumns.bind(this);
		this.onSaveTableSettings   = this.onSaveTableSettings.bind(this);
		this.onScreenResize        = this.onScreenResize.bind(this);
		this.setColumns            = this.setColumns.bind(this);
		this.onClickHeader         = this.onClickHeader.bind(this);
		this.onPaginationRefresh   = this.onPaginationRefresh.bind(this);
		this.createDefaultColumns  = this.createDefaultColumns.bind(this);
		this.refreshSavedColumns   = this.refreshSavedColumns.bind(this);
		this.onDefault             = this.onDefault.bind(this);
		this.headCell              = this.headCell.bind(this);
	}

	componentDidMount() {
		const { getSavedTables, tableType, columns, listReload, filterApply /*sortingRefresh, rowKey*/ } = this.props;
		// sortingRefresh(tableType, {
		//   sortBy: rowKey,
		//   sortOrder: (tableType === TABLE_TYPES.homepageUpcomingEents || tableType === TABLE_TYPES.homepageSpecialOffers) ? 'asc' : 'desc',
		// });
		getSavedTables(tableType, listReload || filterApply);
		this.setColumns(columns);
		//window.addEventListener('resize', this.onScreenResize, true);
	}

	componentDidUpdate(nextProps) {
		const { columns, savedColumns } = this.props;
		const { modifiedColumns, realColumns } = this.state;
		if (nextProps.savedColumns !== savedColumns && !isEmpty(realColumns)) {
			this.onScreenResize();
			this.setColumns(columns);
		}
		if (isEmpty(modifiedColumns)) this.setColumns(columns);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onScreenResize, true);
	}

	// Service --------------------------------------------------------------------------------------

	setColumns(data) {
		const { cols, lang, readOnly } = data;
		const columns = cols.map(item => {
			const dataIndex = item.id;
			const title =  this.headCell(dataIndex, lang[dataIndex]);
			return createColumn(dataIndex, title, item.render);
		});
		if (readOnly) {
			const actionColumn = columns.find(column => column.key === 'action');
			if (actionColumn) {
				const index = columns.indexOf(actionColumn);
				columns.splice(index, 1);
			}
		}

		this.setState({ modifiedColumns: columns });
	}

	createDefaultColumns(cols) {
		const defaultColumns = cols.map(col => {
			const key = col.id || col;
			return {
				key,
			};
		});

		return defaultColumns;
	}

	refreshSavedColumns() {
		const { columns, savedColumns } = this.props;
		const { cols } = columns;
		const keys = cols.map(col => col.id);
		savedColumns.forEach((savedColumn, index) => {
			if (keys.indexOf(savedColumn.key) === -1) {
				savedColumns.splice(index, 1);
			}
		});
		return savedColumns;
	}

	getVisibleColumns() {
		const { columns } = this.props;
		let { savedColumns } = this.props;
		const { cols, defaultKeys } = columns;
		savedColumns = this.refreshSavedColumns();

		const result = isArray(savedColumns) && !isEmpty(savedColumns) ? savedColumns : this.createDefaultColumns(defaultKeys || cols);

		return result;
	}

	getRealColumns() {
		let { savedColumns } = this.props;
		const { modifiedColumns } = this.state;
		savedColumns = this.refreshSavedColumns();
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const result = savedColumns.length ? savedColumns.map(col => {
			const keyColumn = modifiedColumns.find(column => column.key === col.key);
			const { width } = col;
			if (keyColumn) {
				col = keyColumn;
				col.width = width;
			}
			return col;
		}) : null;

		return  modifiedColumns;
	}

	prepareVisibleColumns() {
		const { tableType, resizableAction } = this.props;
		const id = tableType;
		const visibleColumns = this.getVisibleColumns();
		const columns = this.getRealColumns();
		if (!isArray(visibleColumns) || isEmpty(visibleColumns)) {
			return columns;
		}

		let defWidth = 0;
		let quantity = visibleColumns.length;
		const wrap = document.querySelector(`#${id}`);

		if (wrap) {
			defWidth = parseFloat(wrap.offsetWidth) - visibleColumns.length - 1;
		}

		const result = columns.filter(column => {
			if (!resizableAction && (column.key === 'action' || column.key === 'actions')) column.width = 100;
			if (column.key === 'check') column.width = 40;
			return visibleColumns.find(item => {
				if (item.key === column.key && column.width && defWidth) {
					const reg = /.+?%/.exec(column.width);
					if (reg && reg['0']) {
						const percentWidth = reg['0'].slice(0, -1);
						column.width = (wrap.offsetWidth * percentWidth) / 100;
					}
					defWidth -= column.width;
					quantity -= 1;
				}
				return item.key === column.key;
			});
		});
		defWidth = quantity && defWidth / quantity > 10 ? defWidth / quantity : 50;

		return { result, defWidth };
	}

	onScreenResize() {
		const realColumns = this.prepareVisibleColumns();
		this.setState({ realColumns });
	}

	// Local Events ---------------------------------------------------------------------------------

	onSaveTableSettings(visibleColumns) {
		this.setSettings(visibleColumns);
	}

	setSettings(settings) {
		const { saveTables, tableType, pagination } = this.props;
		const typeID = ADMIN_TABLE_TYPES[tableType];
		saveTables([
			{
				type_id : typeID,
				settings: {
					columns     : settings,
					itemsPerPage: pagination.itemsPerPage,
				},
			},
		]);
	}

	onClickHeader(sorting) {
		const { sortingRefresh, filterApply, tableType, loadingUI } = this.props;
		sortingRefresh(tableType, sorting);
		const betRtmExcludedFields = ['eventStartingTime', 'settlementProviderID'];
		const casinoRTMExcludedFields = ['GGR', 'gameWebsiteCurrencyID'];
		const homepageSpecialOffersExcludedFields = ['actionEvent'];
		const matchesOfTheDayExcludedFields = ['category'];
		if ( !loadingUI ) {
			if (tableType === TABLE_TYPES.homepageMatchOfTheDay && matchesOfTheDayExcludedFields.indexOf(sorting.sortBy) > -1 ) return;
			if (tableType === TABLE_TYPES.betRTM && betRtmExcludedFields.indexOf(sorting.sortBy) > -1) return;
			if (tableType === TABLE_TYPES.casinoRTM && casinoRTMExcludedFields.indexOf(sorting.sortBy) > -1) return;
			if (tableType === TABLE_TYPES.homepageSpecialOffers && homepageSpecialOffersExcludedFields.indexOf(sorting.sortBy) > -1 ) return;
			filterApply(tableType);
		}
	}

	onPaginationRefresh(value) {
		const { tableType, paginationRefresh, saveTables, pagination } = this.props;
		const { realColumns } = this.state;
		const actualColumns = isEmpty(realColumns) ? this.prepareVisibleColumns() : realColumns;
		paginationRefresh(tableType, value);
		const typeID = ADMIN_TABLE_TYPES[tableType];
		saveTables([
			{
				type_id : typeID,
				settings: {
					columns     : actualColumns.result,
					itemsPerPage: value.itemsPerPage || pagination.itemsPerPage,
				},
			},
		]);
	}

	onDefault() {
		const { columns } = this.props;
		const { defaultKeys, cols } = columns;
		const defaultColumns = defaultKeys ? defaultKeys.map(key => {
			return {
				key,
			};
		}) : cols.map(col => {
			return {
				key: col.id,
			};
		});
		this.setSettings(defaultColumns);
	}

	// Renders --------------------------------------------------------------------------------------

	headCell(dataIndex, title) {
		const { tableType, nonSortableColumns } = this.props;

		return (
			<HeaderCell
				type={tableType}
				id={dataIndex}
				title={title}
				nonSortableColumns={nonSortableColumns}
				onClick={this.onClickHeader}
			/>
		);
	}

	render() {
		const { rowKey, tableLoading, dataSource, footer, tableType, saveTables, cutCells, sortableColumns, sortedColumn, savedColumns, expandingData, listReload, filterApply, pagination, loading, loadingUI, noPagination, customColorsRows, tableRowSocketUpdate, rowColors, rowColorKey } = this.props;
		const { realColumns, modifiedColumns }  = this.state;
		const load            = loading || tableLoading;
		const hasData         = !load && dataSource && dataSource.length;
		const visibleColumns  = this.getVisibleColumns();

		let actualColumns     = isEmpty(realColumns) ? this.prepareVisibleColumns() : realColumns;
		actualColumns         = isUndefined(actualColumns.result) ? { result: actualColumns } : actualColumns;
		return (
			<>
				<Wrapper id={tableType}>
					{!!hasData && (
						<SettingsControl
							id={tableType}
							columns={modifiedColumns}
							savedColumns={savedColumns}
							visibleColumns={visibleColumns}
							onSave={this.onSaveTableSettings}
							onDefault={this.onDefault}
						/>
					)}
					<TableDragResize
						loading={load}
						tableLoading={tableLoading}
						customColorsRows={customColorsRows}
						rowColors={rowColors}
						rowColorKey={rowColorKey}
						highlightID={tableRowSocketUpdate}
						rowKey={rowKey}
						dataSource={dataSource}
						columns={actualColumns.result}
						footer={footer}
						tableType={tableType}
						saveColumns={saveTables}
						cutCells={cutCells}
						defWidth={actualColumns.defWidth}
						sortableColumns={sortableColumns}
						sortedColumn={sortedColumn}
						expandingData={expandingData}
						loadingUI={loadingUI}
					/>
				</Wrapper>
				{(!noPagination && !!hasData) && (
					<ListPagination
						pagination={pagination}
						onChange={this.onPaginationRefresh}
						onChangePage={listReload || filterApply}
						onChangeSize={listReload || filterApply}
					/>
				)}
			</>
		);
	}
}

function mapStateToProps(state, props) {
	const { tableType } = props;

	const sorting   = deriveTableSorting(tableType, state);
	const columns   = deriveTableSavedColumns(tableType, state);

	return {
		savedColumns: columns,
		sortedColumn: sorting.sortBy,
		pagination  : deriveTablePagination(tableType, state),
		tableLoading: deriveTableLoading(tableType, state),
	};
}

const mapDispatchToProps = {
	getSavedTables   : tablesActions.getSavedTables,
	saveTables       : tablesActions.saveTables,
	sortingRefresh   : tablesActions.sortingRefresh,
	paginationRefresh: tablesActions.paginationRefresh,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListTable);
