import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import { cloneDeep, toInteger, isEmpty } from 'lodash';

import actions from './actions';
import tableActions from '../../../tables/actions';
import integratorsActions from '../../../casino/integrators/actions';

import notifications, { showError, showSuccess } from '../../../../helpers/notifications';
import { bonusesAPI } from '../../../../helpers/api/bonuses';
import { casinoAPI } from '../../../../helpers/api/casino';
import { historyAPI } from '../../../../helpers/api/history';
import { getHeadersTotalCount } from '../../../../helpers/utils';
import { deriveTablePagination } from '../../../../selectors/tables';
import { BONUS_TYPES } from '../../../../constants/bonuses';
import {
	getListParams,
	adaptList,
	adaptBets,
	adaptHistory,
	prepareBonusData,
	adaptLineAndDenomination,
	adaptBonusItem,
	createTemplateDependentFiels,
	prepareBonusDataWithTemplate,
	mergeBonusAndTemplatePerCurrValues,
} from './utils';
import { TABLE_TYPES } from '../../../../constants/tableTypes';
import { adaptGamesList } from '../../../casino/games/utils';
import { deriveBonusesEntities } from '../../../../selectors/bonuses';
import { logger } from '../../../../helpers/logger';
import { LANGUAGES } from '../../../../helpers/commonConstants';
import appTabsActions from '../../../appTabs/actions';
import { CasinoExtraSpinType } from '@ceo-betmakers/common-globals';
import { adaptBonusTemplate, adaptBonusTemplates } from '../../templates/utils';
import { bonusTemplatesAPI } from '../../../../helpers/api/bonusTemplates';
import { EBonusTemplateStatus } from '../../templates/types';
import { getCurrencies } from '../../../settings/currency/utils';

const messages = {
	errorListLoad        : 'bonuses.bonuses.errorListLoad',
	errorDelete          : 'bonuses.bonuses.errorDelete',
	errorSave            : 'bonuses.bonuses.errorSave',
	errorGameLimitLoad   : 'bonuses.bonuses.errorGameLimitLoad',
	errorHistoryLoad     : 'bonuses.bonuses.errorHistoryLoad',
	errorCurrencyLoad    : 'bonuses.bonuses.errorCurrencyLoad',
	errorTemplateListLoad: 'bonuses.bonuses.errorTemplateListLoad',
	errorTemplateLoad    : 'bonuses.bonuses.errorTemplateLoad',

	successDelete          : 'bonuses.bonuses.successDelete',
	successSave            : 'bonuses.bonuses.successSave',
	successSaveWithTemplate: 'bonuses.bonuses.successSaveWithTemplate',
};

const tableType   = TABLE_TYPES.bonusesRegistrationFreeSpin;

function getStoreData(state) {
	const { Loyalty: { Bonuses }, Tables, App, Partner, Settings } = state;
	const { Bonuses : typeBonuses } = Bonuses;
	const UI = typeBonuses.get('UI');
	const providerIDs = Bonuses.UserBonus.get('checkedProvidersByIntegrator');

	return {
		filter                       : typeBonuses.get('filter'),
		sorting                      : Tables.get(tableType).sorting,
		pagination                   : deriveTablePagination(tableType, state),
		bonusData                    : typeBonuses.get('bonusData'),
		bets                         : typeBonuses.get('bets'),
		betID                        : UI.betID,
		editMode                     : UI.editMode, // TODO it will be chenged in the near future
		closeModal                   : UI.closeModal,
		denominationKey              : UI.denominationKey,
		denominationValue            : UI.denominationValue,
		freeSpinType                 : UI.freeSpinType,
		bonusType                    : UI.bonusType,
		showLineAndDenominationInputs: UI.showLineAndDenominationInputs,
		websiteID                    : App.get('websiteID'),
		providerIDs,
		bonusEntities                : deriveBonusesEntities(state),
		contentLanguageList          : App.get('websiteAttachedLanguages'),
		innerEditMode                : !!window.location.pathname.includes('edit'), // TODO it will be chenged in the near future
		countriesList                : Partner.Partner.Modal.Countries.get('baseData').countriesList,
		currencyList                 : Settings.CurrencyModule.get('list'),
	};
}

function* listReload() {

	yield takeEvery(actions.LIST_RELOAD, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		const { filter, sorting, pagination, currencyList } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		if (params.affiliate_reference) {
			params.affiliate_reference = [params.affiliate_reference];
		}
		let entities   = {};
		let totalCount = 0;
		let list = [];
		try {
			const res = yield call(bonusesAPI.bonusesList, params);
			if (res && res.status === 200) {
				const currencies = yield getCurrencies(currencyList);
				const { adaptedData, bonusEntities } = adaptList(res.data.data, currencies);
				entities = bonusEntities;
				list = adaptedData;

				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.listRefresh(entities));
				yield put(actions.bonusListRefresh(list));
			}
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}
function* getBonusByID() {

	yield takeEvery(actions.GET_BY_ID, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(bonusesAPI.getByID, data);
			if (res && res.status === 200) {

				const bonus = adaptBonusItem(res.data.data);
				if (bonus.templateID) {
					const { countriesList } = yield select(getStoreData);
					const { data } = yield call(bonusTemplatesAPI.getTemplate, bonus.templateID);
					const adapted = adaptBonusTemplate(data.data);
					adapted.count = data.data.count || {
						providers : {},
						categories: {},
					};
					const templateDependentFields	= createTemplateDependentFiels(adapted, countriesList);
					const { currencies, currencyID }= mergeBonusAndTemplatePerCurrValues(bonus.currencies, templateDependentFields.currencies);

					const mergedBonusAndTemplate	= {
						...bonus,
						...templateDependentFields,
						currencyID,
						currencies,
						titles             : bonus.titles,
						currencyRequiring  : currencyID,
						currenciesRequiring: currencies,
					};
					yield put(actions.uiRefresh({ withTemplate: true, templateID: bonus.templateID }));
					yield put(actions.dataRefresh(mergedBonusAndTemplate));
				} else {
					yield put(actions.dataRefresh(bonus));
				}
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* gameReload() {

	yield takeEvery(actions.GAME_ENTITY_RELOAD, function* ({ casinoGameID, currencyID, websiteID, langID, isFilterBuilder }) {
		yield put(actions.uiRefresh({ modalLoad: true }));
		const params = {
			lang_id    : langID,
			currency_id: currencyID,
			id         : casinoGameID,
		};

		try {
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				if (isFilterBuilder) {
					const [gameEntity] = adaptedData;
					yield put(actions.gameEntitySet(gameEntity));
				}
				yield put(actions.gamesListRefreshLimited(adaptedData));
			}

		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ modalLoad: false }));
	});
}

function* gamesListLimitedReload() {

	yield takeEvery(actions.NEW_GAMES_BY_PAGE_RELOAD, function* (action) {
		const { bonusData } = yield select(getStoreData);
		const { currencyID } = bonusData;

		const { websiteID, casinoID, channelID, byCategory, name, providerType, page, limit, isUnlimit, isSearch, typeID } = action.data;
		let params;
		if (providerType === 'provider') {
			params = {
				unlimit    : isUnlimit,
				channel_id : channelID,
				provider_id: casinoID,
				categories : byCategory,
				name,
			};
		} else {
			params = {
				unlimit           : isUnlimit,
				channel_id        : channelID,
				custom_provider_id: casinoID,
				name,
				currency_id       : currencyID,

			};
		}

		if (typeID) {
			const isFreeSpin = typeID === CasinoExtraSpinType.FREE_SPIN;
			if (isFreeSpin) {
				params.free_spins = true;
			} else {
				params.bonus_spins = true;
			}
		}

		params.page = page;
		params.limit = limit;

		let totalCount;

		let gamesList = [];
		try {
			const res = yield call(casinoAPI.gamesList, websiteID, params);
			if (res && res.status === 200) {
				const { adaptedData } = adaptGamesList(res.data.data);
				gamesList = adaptedData;
				totalCount = getHeadersTotalCount(res.headers);
				yield put(actions.gamesListRefreshLimited(gamesList));
				yield put(actions.uiRefresh({ isGamesChanged: false }));
			}

		} catch (error) {
			notifications.showError(messages.errorListLoad);
			logger.log(error);
		}

		if ( isSearch ) {
			yield put(actions.gamesListRefreshFilteredLimited(gamesList));
		} else {
			yield put(actions.gamesListRefreshLimited(gamesList));
		}

		yield put(actions.gamesListHasMoreRefresh(isEmpty(gamesList) ? false : totalCount));
		yield put(actions.uiRefresh({ loading: false }));
	});
}


function* filterApply() {
	yield takeEvery(actions.FILTER_APPLY, function* () {
		yield put(tableActions.paginationRefresh(tableType, { currentPage: 1 }));
		yield put(actions.listReload());
	});
}

function* bonusSave() {

	yield takeEvery(actions.BONUS_SAVE, function* () {

		const {
			bonusEntities,
			bonusData,
			editMode : tempEditMode, // TODO it will be chenged in the near future
			closeModal,
			denominationKey,
			denominationValue,
			websiteID,
			freeSpinType,
			bonusType,
			bets,
			betID,
			contentLanguageList,
			showLineAndDenominationInputs,
			innerEditMode, // TODO it will be chenged in the near future
		} = yield select(getStoreData);
		const limits = {
			denominationKey,
			denominationValue,
			bets,
			betID,
		};
		const resData      = cloneDeep(bonusData);
		const editMode = tempEditMode || innerEditMode;
		resData.websiteID  = websiteID;
		const isNotFoundEnglish = !contentLanguageList.find(item => item.id === LANGUAGES.en);
		if (isNotFoundEnglish && resData?.titles.length) {
			const [anyLang] = resData.titles;
			if (!resData?.titles.find(item => item.langID === LANGUAGES.en)) {

				resData?.titles.push({
					langID     : LANGUAGES.en,
					title      : anyLang.title,
					description: anyLang.description,
				});
			}
		}
		//prepare
		const preparedData = prepareBonusData(resData, limits, freeSpinType, bonusType, editMode, showLineAndDenominationInputs);
		let bonusID = resData.id;
		let isError = false;
		try {
			let updated;
			if (editMode) {
				yield put(actions.uiRefresh({ modalLoad: true }));

				if (bonusType === BONUS_TYPES.registrationFreeSpin) {
					updated = yield call(bonusesAPI.registrationFreeSpinUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.depositFreeSpin) {
					updated = yield call(bonusesAPI.depositFreeSpinUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
					updated = yield call(bonusesAPI.nextDepositFreeSpinUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.deposit) {
					updated = yield call(bonusesAPI.depositUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositBonus) {
					updated = yield call(bonusesAPI.nextDepositUpdate, bonusID, preparedData);
				} else {
					updated = yield call(bonusesAPI.packUpdate, bonusID, preparedData);
				}
				const response = updated.data.data;
				resData.logoURL = response.logo_url;

			} else {
				yield put(actions.uiRefresh({ loading: true }));

				let res;
				if (bonusType === BONUS_TYPES.depositFreeSpin) {
					res = yield call(bonusesAPI.depositFreeSpinCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.registrationFreeSpin) {
					res = yield call(bonusesAPI.registrationFreeSpinCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.deposit) {
					res = yield call(bonusesAPI.depositCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
					res = yield call(bonusesAPI.nextDepositFreeSpinCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositBonus) {
					res = yield call(bonusesAPI.nextDepositCreate, preparedData);
				} else {
					res = yield call(bonusesAPI.packCreate, preparedData);
				}

				const response = res.data.data;
				resData.logoURL = response.logo_url;

				bonusID = toInteger(res.data.data.id);
				resData.id = bonusID;
				resData.bonusType = response.bonus_type;
				yield put(actions.uiRefresh({ saveSuccess: true }));
				yield put(actions.historyLogsReload(bonusID));
				yield put(actions.uiRefresh({ editMode: true }));

				yield put(appTabsActions.openTabBonusesList());
			}

			showSuccess(messages.successSave);
			yield put(actions.uiRefresh({ isChanged: false }));

		} catch (error) {
			isError = true;
			showError(messages.errorSave);
		}
		bonusEntities[bonusData.uniqueID] = resData;
		yield put(actions.dataRefresh(resData));
		yield put(actions.listRefresh(bonusEntities));
		yield put(actions.bonusListRefresh(Object.values(bonusEntities)));

		if (!isError && closeModal) {
			yield put(actions.dataReset());
			!editMode && (yield put(actions.listReload()));

		} else if (!isError || !closeModal) {
			yield put(actions.uiRefresh({ loading: false, modalLoad: false }));
			!editMode && (yield put(actions.listReload()));

		} else {
			yield put(actions.uiRefresh({ loading: false, modalLoad: false }));
		}

		const { currencyID } = resData;
		!editMode && (yield put(integratorsActions.listReload({ withProvider: true, currencyID })));
	});
}

function* bonusSaveWithTemplate() {
	yield takeEvery(actions.BONUS_SAVE_WITH_TEMPLATE, function* ({ data }) {
		yield put(actions.uiRefresh({ loading: true }));
		const { bonusData, websiteID, innerEditMode : editMode } = yield select(getStoreData);

		const { bonusType } = data;
		const resData		= cloneDeep(bonusData);
		resData.websiteID	= websiteID;
		let bonusID			= resData.id;
		try {
			const preparedData = prepareBonusDataWithTemplate(resData, bonusType);

			if (editMode) {
				let updated;

				if (bonusType === BONUS_TYPES.registrationFreeSpin) {
					updated = yield call(bonusesAPI.registrationFreeSpinWithTemplateUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.depositFreeSpin) {
					updated = yield call(bonusesAPI.depositFreeSpinWithTemplateUpdate, bonusID, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
					updated = yield call(bonusesAPI.nextDepositFreeSpinWithTemplateUpdate, bonusID, preparedData);
				}
				const response = updated.data.data;
				resData.logoURL = response.logo_url;
			} else {

				let res;
				if (bonusType === BONUS_TYPES.registrationFreeSpin) {
					res = yield call(bonusesAPI.registrationFreeSpinWithTemplateCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.depositFreeSpin) {
					res = yield call(bonusesAPI.depositFreeSpinWithTemplateCreate, preparedData);
				} else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
					res = yield call(bonusesAPI.nextDepositFreeSpinWithTemplateCreate, preparedData);
				}

				const response = res.data.data;
				resData.logoURL = response.logo_url;

				bonusID = toInteger(res.data.data.id);
				resData.id = bonusID;
				resData.bonusType = response.bonus_type;
				yield put(actions.uiRefresh({ saveSuccess: true }));
				yield put(actions.historyLogsReload(bonusID));
				yield put(appTabsActions.openTabBonusesList());
			}
			showSuccess(messages.successSaveWithTemplate);

		} catch (error) {
			showError(messages.errorTemplateLoad);
			logger.log(error);
		}
		yield put(actions.dataRefresh(resData));

		yield put(actions.uiRefresh({ loading: false }));
		// const { currencyID } = resData;
		// !editMode && (yield put(integratorsActions.listReload({ withProvider: true, currencyID }))); TODO need check
	});
}

function* gameLimitsReload() {

	yield takeEvery(actions.GAME_LIMITS_RELOAD, function* (action) {

		yield put(actions.uiRefresh({ modalLoad: true }));

		const { casinoGameID, currencyCode, withRefresh } = action.data;
		const params      = {
			currency_code: currencyCode,
		};
		let bets          = [];
		let denominations = {};

		try {
			const res = yield call(casinoAPI.gameBetLimits, casinoGameID, params);
			if (res && res.status === 200) {
				const { data } = res.data;
				bets = adaptBets(data.bets);
				const lineAndDenominationReq = adaptLineAndDenomination(data);
				denominations = data?.denominations;
				const isBetsOrDenominations = Boolean(data.denominations || data.bets);
				yield put(actions.uiRefresh({ showLineAndDenominationInputs: !isBetsOrDenominations }));
				if (!isBetsOrDenominations) {
					yield put(actions.uiRefresh({ showLineAndDenominationCheckbox: !(lineAndDenominationReq.requiredBetPerLine || lineAndDenominationReq.requiredLines) }));
				}

				yield put(actions.denominationsAndLineRefresh(lineAndDenominationReq));
			}

		} catch (error) {
			showError(messages.errorGameLimitLoad);
		}

		yield put(actions.betsRefresh(bets));
		yield put(actions.denominationsRefresh(denominations));
		if (withRefresh) {
			yield put(actions.uiRefresh({
				betID            : null,
				denominationKey  : null,
				denominationValue: null,
				modalLoad        : false,
			}));

		} else {
			yield put(actions.uiRefresh({ modalLoad: false }));
		}
	});
}

function* historyLogsReload() {

	yield takeEvery(actions.HISTORY_LOGS_RELOAD, function* (action) {

		const {  bonusType } = yield select(getStoreData);
		const { bonusID } = action.data;
		let historyLogs = [];
		let res;
		try {
			if (bonusType === BONUS_TYPES.registrationFreeSpin) {
				res = yield call(historyAPI.historyRegistrationFreeSpinLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.depositFreeSpin) {
				res = yield call(historyAPI.historyDepositFreeSpinLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.deposit) {
				res = yield call(historyAPI.historyDepositsLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.nextDepositFreeSpin) {
				res = yield call(historyAPI.historyNextDepositFreeSpinLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.nextDepositBonus) {
				res = yield call(historyAPI.historyNextDepositBonusLogs, bonusID);
			} else if (bonusType === BONUS_TYPES.packNext) {
				res = yield call(historyAPI.historyPackNextLogs, bonusID);
			} else {
				res = yield call(historyAPI.historyPacksLogs, bonusID);
			}
			if (res && res.status === 200) {
				historyLogs = adaptHistory(res.data.data);
				yield put(actions.historyLogsRefresh(historyLogs));
			}
		} catch (error) {
			showError(messages.errorListLoad);
			logger.log(error);
		}

		yield put(actions.historyLogsRefresh(historyLogs));
	});
}

function* templateListReload() {
	yield takeEvery(actions.BONUS_TEMPLATES_LIST_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));
		const params = {
			template_type: action.templateType,
			status_ids   : [EBonusTemplateStatus.ACTIVE],
		};

		try {
			const { data } = yield call(bonusTemplatesAPI.getTemplates, params);
			const adapted = adaptBonusTemplates(data.data);
			yield put(actions.setTemplates(adapted));
		} catch (error) {
			showError(messages.errorTemplateListLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

function* getTemplate() {
	yield takeEvery(actions.BONUS_TEMPLATE_BY_ID_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { countriesList } = yield select(getStoreData);
		try {
			const { data } = yield call(bonusTemplatesAPI.getTemplate, action.templateID);
			const adapted = adaptBonusTemplate(data.data);
			adapted.count = data.data.count || {
				providers : {},
				categories: {},
			};
			const templateDependentFields = createTemplateDependentFiels(adapted, countriesList, true);

			yield put(actions.dataRefresh(templateDependentFields));
		} catch (error) {
			showError(messages.errorTemplateLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ loading: false }));
	});
}

export default function* bonusesSaga() {
	yield all([
		fork(getBonusByID),
		fork(gameReload),
		fork(listReload),
		fork(filterApply),
		fork(bonusSave),
		fork(gameLimitsReload),
		fork(historyLogsReload),
		fork(gamesListLimitedReload),
		fork(templateListReload),
		fork(getTemplate),
		fork(bonusSaveWithTemplate),
	]);
}
